.wrapper {
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    padding: 5vh 4rem 5rem 4rem;
    text-align: center;

    hr {
        max-width: 25rem;
        margin: 10rem auto 2rem auto;
    }

    img {
        width: 5rem;
        height: auto;
        display: block;
        margin: 0 auto 0.5rem auto;
        filter: invert(1);
    }

    a {
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--color-2-dark);
    }
}

.icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    font-size: 15rem;
    color: var(--color-2-contrast);
}