.container {
    width: 100%;
    margin-bottom: 5rem;
}

.title {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    font-weight: 800;
}

.order {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);
    cursor: pointer;
}

.wrapper {
    height: 100%;
    flex: 1;
    padding-right: 1rem;
}

.orderNumber {
    margin-bottom: 0.2rem;
    font-size: 1.8rem;
    font-weight: 600;
}

.timestamp {
    margin-top: 0.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.button {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.8rem;
    border-radius: 5rem;
    background-color: var(--custom-color-1);
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-2);
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 2.5rem;
    }
}

.close {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 3.5rem;
    color: var(--color-2-dark);
}

.summary {
    width: 100%;
    margin: 2rem 0;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.amount {
    width: 3rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.name {
    flex: 1;
    padding-right: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.price {
    min-width: 5rem;
    text-align: right;
    font-size: 1.6rem;
    font-weight: 600;
}

.line {
    width: 100%;
    height: 1px;
    margin: 2.5rem 0 1.5rem 0;
    background-color: var(--color-2-contrast);
}

.total {
    width: 100%;
    text-align: right;
    font-size: 1.8rem;
    font-weight: 600;
}

.note {
    font-size: 1.5rem;
    font-weight: 500;
}