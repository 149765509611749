@import "stylesheets/animations";

.banner {
    position: fixed;
    right: 0;
    bottom: 2rem;
    left: 0;
    width: calc(100% - 4rem);
    max-width: 55rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-1);
    animation: $fadeInFromBottom;
    animation-delay: 1.5s;
    font-size: 1.2rem;
    color: var(--color-2);
    z-index: 999;

    div {
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
    }

    p {
        color: var(--color-2);
    }

    a {
        text-decoration: underline;
        font-weight: 600;
        color: var(--color-2);
    }
}