.backdrop {
    position: fixed;
    top: var(--nav-height);
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: var(--padding);
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s;
    animation: fadein 0.5s forwards;
    overflow: scroll;
    z-index: 99;
}

.modal {
    width: 100%;
    max-width: 70rem;
    margin: 2rem auto 7rem auto;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    animation: enter 0.5s;

    h1 {
        font-size: 2.5rem;
    }
}

.small {
    @extend .modal;
    max-width: 50rem;
}

.disappear {
    animation: exit 0.5s forwards;
}

.fadeout {
    animation: unset;
    background-color: rgba(0, 0, 0, 0);
}

@keyframes fadein {
    to {
        background-color: rgba(91, 111, 140, 0.4);
    }
}

@keyframes enter {
    0% {
        transform: translateY(2rem);
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes exit {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: translateY(2rem);
        opacity: 0;
    }
}

@media screen and (min-width: 768px) {
    .modal {
        padding: 2.5rem;
    }
}