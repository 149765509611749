@import "stylesheets/animations";

.container {
    width: 100%;
    padding: calc(var(--nav-height) + var(--padding)) 2rem 22rem 2rem;
    animation: $fadeInFromBottom;
    animation-delay: 0.1s;
}

.backToMenu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    font-size: 2.2rem;
    color: var(--color-2-dark);

    span {
        margin-top: 1px;
        margin-left: 0.5rem;
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.box {
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}

.amount {
    width: 3rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
}

.name {
    flex: 1;
    padding-right: 1rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.price {
    min-width: 5rem;
    text-align: right;
    font-size: 1.6rem;
    font-weight: 600;
}

.line {
    height: 1px;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    background-color: var(--color-2-contrast);
}

.total {
    width: 100%;
    padding: 0 1.5rem;
    text-align: right;
    font-size: 2rem;
    font-weight: 800;
}

.button {
    width: 100%;
    height: 5rem;
    display: block;
    margin: 0 auto;
    border-radius: var(--border-radius);
    background-color: var(--custom-color-1);
    transition: background-color 0.2s;
    font-weight: 500;
    color: var(--color-2);

    &:disabled {
        background-color: var(--color-2-contrast);
    }
}

.confirmButton {
    @extend .button;
    margin-top: 3rem;
}

.tip {
    width: 100%;
    margin-bottom: 2rem;
}

.spinner {
    width: 100%;
    margin-top: -1rem;
}

.confirmation {
    width: 100%;
    max-width: 55rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    animation: $fadeInFromBottom;
    text-align: center;

    p {
        margin-bottom: 4rem;
        font-size: 2rem;
    }
}

.tick {
    width: 100%;
    height: 12rem;
    margin-top: 5rem;
}

@media screen and (min-width: 768px) {
    .amount {
        font-size: 1.6rem;
    }

    .name {
        font-size: 1.8rem;
    }

    .price {
        font-size: 2rem;
    }
}