@import "stylesheets/animations";

.coverImage {
    position: absolute;
    top: var(--nav-height);
    left: 0;
    right: 0;
    height: 28rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    + div {
        margin-top: 28rem;
    }
}

.container {
    animation: $fadeIn;
    animation-delay: 0.1s;
}

.categories {
    position: sticky;
    top: var(--nav-height);
    right: 0;
    left: 0;
    height: 6rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 2rem;
    padding: 0 2rem;
    background-color: var(--color-2-dimmed);
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.tab {
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5rem;
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
    background-color: var(--color-2-dimmed);
    transition: background-color 0.4s, color 0.4s;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);
    cursor: pointer;

    &:hover {
        color: var(--color-1);
    }
}

.active {
    @extend .tab;
    background-color: var(--color-2);
    color: var(--color-1);
}

.wrapper {
    padding: 0 2rem;
}

.menu {
    margin-top: -2rem;
    padding-bottom: 10rem;

    li {
        margin-top: 2rem;
    }

    > li:first-child {
        margin-top: 5rem;
    }
}