.category {
    margin-top: 4rem;

    > p {
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;

    .information {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-right: 1rem;

        h4 {
            word-break: break-word;
        }

        span {
            font-size: 1.6rem;
            font-weight: 600;
            color: var(--color-2-dark);
        }

        p {
            font-size: 1.4rem;
        }

        .notice {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0.2rem;
            font-size: 1.6rem;
            color: var(--color-2-dark);

            span {
                display: inline-block;
                margin-left: 0.5rem;
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }

    .actions {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
            width: 100%;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.4rem 0.8rem;
            border-radius: 5rem;
            background-color: var(--custom-color-1);
            font-size: 1.1rem;
            font-weight: 600;
            color: var(--color-2);
        }
    }
}

.dropdown {
    margin-top: 2rem;
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
}