.footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-min-height);
    background-color: var(--color-2-dimmed);
    z-index: 10;
}

.container {
    position: relative;
    width: 100%;
    max-width: var(--max-width);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 4rem 2rem;
}

.language {
    position: relative;
    width: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
}

.button {
    transition: color 0.2s;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-2-dark);

    &:hover,
    &:hover .icon {
        color: var(--color-1);
    }

    &:not(:disabled):active {
        transform: unset;
        -webkit-transform: unset;
    }
}

.icon {
    transition: color 0.2s;
    font-size: 2.5rem;
    color: var(--color-2-dark);
}

.translations {
    position: absolute;
    top: -24.5rem;
    left: -2.5rem;
    width: 15rem;
    height: 24.5rem;
    padding: 1rem 0;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-contrast);
    background-color: var(--color-2-dimmed);
    box-shadow: var(--shadow);
    overflow: scroll;

    div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 2rem;
        transition: color 0.2s;
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--color-2-dark);
        cursor: pointer;

        &:hover {
            color: var(--color-1);
        }
    }

    img {
        width: 2.1rem;
        height: 1.5rem;
        display: inline-block;
        margin-right: 1rem;
    }
}

.notice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 3rem;
        height: auto;
        display: block;
        margin-bottom: 1rem;
    }

    a {
        font-size: 1rem;
        font-weight: 500;
        color: var(--color-2-dark);
    }
}