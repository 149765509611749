@import "stylesheets/animations";

.nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: var(--nav-height);
    background-color: var(--custom-color-1);
    backdrop-filter: blur(15px) saturate(1.2);
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.05);
    z-index: 99;
}

.container {
    position: relative;
    width: 100%;
    max-width: var(--max-width);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
}

.logo {
    width: 15rem;
    height: 65%;
    object-fit: contain;
    object-position: left center;
}

.cart {
    position: relative;
    width: 5rem;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    font-size: 3.2rem;
    color: var(--color-2);
    cursor: pointer;
}

.count {
    position: absolute;
    right: -2px;
    bottom: 17%;
    min-width: 1.9rem;
    height: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 3px;
    border-radius: 2rem;
    background-color: var(--color-5);
    animation: $fadeInFromBottom;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--color-2);
}

.menu {
    position: absolute;
    top: 6rem;
    right: 2rem;
    width: 90%;
    max-width: 35rem;
    padding: 2rem;
    border-radius: var(--border-radius-large);
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    box-shadow: var(--shadow);
    animation: $fadeInFromBottom;
    z-index: 999;

    ul {
        width: 100%;
        height: 100%;
    }
}

.placeholder {
    width: 100%;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: $fadeIn;
    text-align: center;

    .icon {
        font-size: 7rem;
        color: var(--color-2-contrast);
    }

    .title {
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: 600;
    }

    p {
        font-size: 1.4rem;
        font-weight: 500;
    }
}

.items {
    width: 100%;
    max-height: 70vh;
    scrollbar-width: none;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.item {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    transition: transform 0.4s, opacity 0.4s;

    .amount {
        width: 4rem;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 500;
        color: var(--color-2-dark);
    }

    .price {
        font-weight: 600;
    }

    .information {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
    }

    .action {
        width: 7rem;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 1.4rem;
        color: var(--color-2-dark);
    }
}

.checkout {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--custom-color-1);
    font-weight: 500;
    color: var(--color-2);

    &:focus {
        color: var(--color-2);
    }
}